import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  PrismaJson: any;
};

export type Account = {
  __typename?: 'Account';
  AccountSubscription: AccountSubscription;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
  stripeSubscription: Maybe<StripeSubscription>;
  updatedAt: Scalars['Date'];
};

export type AccountSubscription = {
  __typename?: 'AccountSubscription';
  Plan: Plan;
  id: Scalars['ID'];
};

export type ApiKey = {
  __typename?: 'ApiKey';
  createdAt: Scalars['Date'];
  id: Maybe<Scalars['String']>;
  key: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type ApiKeysUsagePayload = {
  __typename?: 'ApiKeysUsagePayload';
  _id: Scalars['String'];
  averageEnhancementDuration: Maybe<Scalars['Float']>;
  creditsUsed: Scalars['Int'];
  enhancementsCreated: Scalars['Float'];
  uploadsFinished: Scalars['Float'];
};

export type CancelSubscriptionInput = {
  reason: Scalars['String'];
};

export enum CheckoutPlan {
  BusinessPlan_2022V1 = 'BUSINESS_PLAN_2022_v1'
}

export type ConfirmSignupEmailAddressInput = {
  activationKey: Scalars['String'];
  email: Scalars['String'];
};

export type ContactSupportInput = {
  phoneNumber: InputMaybe<Scalars['String']>;
  question: Scalars['String'];
  requestsRepresentative: Scalars['Boolean'];
  type: Scalars['String'];
};

export type CreateCheckoutSessionInput = {
  cancelUrl: Scalars['String'];
  plan: CheckoutPlan;
  successUrl: Scalars['String'];
};

export type CreateCheckoutSessionPayload = {
  __typename?: 'CreateCheckoutSessionPayload';
  url: Scalars['String'];
};

export type CreateEnhancedImageInput = {
  artifactsDetectionRemoval: InputMaybe<EnhancedImageParametersArtifactsDetectionRemoval>;
  /**
   * This will convert to preset: DEFAULT, but remains in the API for backwards compatibility.
   *           This won't do anything if another preset is selected.
   */
  defaultImageEnhancement: InputMaybe<EnhancedImageParametersDefaultImageEnhancement>;
  enhanceFaceDetails: InputMaybe<EnhancedImageParametersEnhanceFaceDetails>;
  preset: InputMaybe<EnhancedImageParametersPreset>;
  upscalingCustomDPI: InputMaybe<Scalars['Int']>;
  upscalingCustomWidth: InputMaybe<Scalars['Int']>;
  upscalingFactor: InputMaybe<EnhancedImageParametersUpscalingFactor>;
};

export type CreateExportPayload = {
  __typename?: 'CreateExportPayload';
  downloadUrl: Maybe<Scalars['String']>;
  enhancedImagesCount: Scalars['Int'];
  exportCreatedAsync: Scalars['Boolean'];
  filename: Maybe<Scalars['String']>;
  unEnhancedImagesCount: Scalars['Int'];
};

export type CreateFolderInput = {
  name: Scalars['String'];
  parentFolderId: InputMaybe<Scalars['String']>;
};

export type CreatePortalSessionInput = {
  returnUrl: Scalars['String'];
};

export type CreatePortalSessionPayload = {
  __typename?: 'CreatePortalSessionPayload';
  url: Scalars['String'];
};

export type CreateSignedUploadUrlInput = {
  filename: Scalars['String'];
  filesize: Scalars['Int'];
  folderId: InputMaybe<Scalars['String']>;
  mimetype: Scalars['String'];
};

export type CreateSignedUploadUrlPayload = {
  __typename?: 'CreateSignedUploadUrlPayload';
  uploadId: Scalars['String'];
  uploadUrl: Scalars['String'];
};

export type CreateUploadFromUrlInput = {
  folderId: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};

export type CreateWebhookInput = {
  url: Scalars['String'];
};

export type CreditsPayload = {
  __typename?: 'CreditsPayload';
  id: Scalars['String'];
  periodEnd: Maybe<Scalars['Date']>;
  periodStart: Scalars['Date'];
  remainingCreditsThisPeriod: Maybe<Scalars['Int']>;
  usedCreditsThisPeriod: Scalars['Int'];
};

export type DeleteMeInput = {
  reason: Scalars['String'];
};

export type DeleteUploadsPayload = {
  __typename?: 'DeleteUploadsPayload';
  deletedUploadIds: Array<Scalars['String']>;
};

export type EnhancedImage = {
  __typename?: 'EnhancedImage';
  EnhancedImageFeedback: Maybe<EnhancedImageFeedback>;
  EnhancedImageParameters: EnhancedImageParameters;
  Upload: Upload;
  Workflow: Maybe<Workflow>;
  duration: Maybe<Scalars['Int']>;
  errorCode: Maybe<Scalars['String']>;
  filename: Maybe<Scalars['String']>;
  fullUrl: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  originUrl: Maybe<Scalars['String']>;
  startedAt: Maybe<Scalars['Date']>;
  status: EnhancedImageStatus;
  uploadId: Scalars['ID'];
  viesusDuration: Maybe<Scalars['Int']>;
};

export type EnhancedImageFeedback = {
  __typename?: 'EnhancedImageFeedback';
  comment: Scalars['String'];
  id: Scalars['ID'];
  score: Scalars['Int'];
};

export type EnhancedImageParameters = {
  __typename?: 'EnhancedImageParameters';
  artifactsDetectionRemoval: Maybe<EnhancedImageParametersArtifactsDetectionRemoval>;
  defaultImageEnhancement: Maybe<EnhancedImageParametersDefaultImageEnhancement>;
  enhanceFaceDetails: Maybe<EnhancedImageParametersEnhanceFaceDetails>;
  id: Scalars['ID'];
  preset: Maybe<EnhancedImageParametersPreset>;
  /** @deprecated superResolution is deprecated, use upscalingFactor instead. */
  superResolution: Maybe<EnhancedImageParametersSuperResolution>;
  upscalingCustomDPI: Maybe<Scalars['Int']>;
  upscalingCustomWidth: Maybe<Scalars['Int']>;
  upscalingFactor: Maybe<EnhancedImageParametersUpscalingFactor>;
};

export enum EnhancedImageParametersArtifactsDetectionRemoval {
  Auto = 'AUTO',
  Enforce = 'ENFORCE',
  Off = 'OFF',
  On = 'ON'
}

export enum EnhancedImageParametersDefaultImageEnhancement {
  Off = 'OFF',
  On = 'ON'
}

export enum EnhancedImageParametersEnhanceFaceDetails {
  Off = 'OFF',
  On = 'ON'
}

export enum EnhancedImageParametersPreset {
  Crisp = 'CRISP',
  DarkShots = 'DARK_SHOTS',
  Default = 'DEFAULT',
  FlashShots = 'FLASH_SHOTS',
  Ice = 'ICE',
  Landscape = 'LANDSCAPE',
  PortraitsEastAsia = 'PORTRAITS_EAST_ASIA',
  PortraitsIndia = 'PORTRAITS_INDIA',
  Warm = 'WARM'
}

export enum EnhancedImageParametersSuperResolution {
  X2 = 'X2',
  X4 = 'X4',
  X6 = 'X6',
  X8 = 'X8',
  X10 = 'X10'
}

export enum EnhancedImageParametersUpscalingFactor {
  Custom = 'CUSTOM',
  X1 = 'X1',
  X2 = 'X2',
  X4 = 'X4',
  X6 = 'X6',
  X8 = 'X8',
  X10 = 'X10'
}

export enum EnhancedImageStatus {
  Deleted = 'DELETED',
  Error = 'ERROR',
  Finished = 'FINISHED',
  Processing = 'PROCESSING',
  Queued = 'QUEUED'
}

export type Folder = {
  __typename?: 'Folder';
  InputWorkflowTriggers: Array<WorkflowTrigger>;
  OutputWorkflowTriggers: Array<WorkflowTrigger>;
  ParentFolder: Maybe<Folder>;
  Uploads: Array<Upload>;
  createdAt: Scalars['Date'];
  id: Scalars['String'];
  name: Scalars['String'];
  parentFolderId: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
  uploadsCount: Scalars['Int'];
};

export type FolderBreadcrumbItem = {
  __typename?: 'FolderBreadcrumbItem';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type FoldersFilterInput = {
  parentFolderId: InputMaybe<Scalars['String']>;
  skipParentFolderCheck: InputMaybe<Scalars['Boolean']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  account_country: Scalars['String'];
  account_name: Scalars['String'];
  account_tax_ids: Scalars['String'];
  amount_due: Scalars['Int'];
  amount_paid: Scalars['Int'];
  amount_remaining: Scalars['Int'];
  application_fee_amount: Scalars['String'];
  attempt_count: Scalars['Int'];
  attempted: Scalars['Boolean'];
  auto_advance: Scalars['Boolean'];
  automatic_tax: InvoiceAutomaticTax;
  billing_reason: Scalars['String'];
  charge: Scalars['String'];
  collection_method: Scalars['String'];
  created: Scalars['Int'];
  currency: Scalars['String'];
  custom_fields: Scalars['String'];
  customer: Scalars['String'];
  customer_address: InvoiceCustomerAddress;
  customer_email: Scalars['String'];
  customer_name: Scalars['String'];
  customer_phone: Scalars['String'];
  customer_shipping: InvoiceCustomerShipping;
  customer_tax_exempt: Scalars['String'];
  customer_tax_ids: Array<Scalars['String']>;
  default_payment_method: Scalars['String'];
  default_source: Scalars['String'];
  default_tax_rates: Array<Scalars['String']>;
  description: Scalars['String'];
  discount: Scalars['String'];
  discounts: Array<Scalars['String']>;
  due_date: Scalars['String'];
  ending_balance: Scalars['String'];
  footer: Scalars['String'];
  hosted_invoice_url: Scalars['String'];
  id: Scalars['String'];
  invoice_pdf: Scalars['String'];
  last_finalization_error: Scalars['String'];
  lines: InvoiceLines;
  livemode: Scalars['Boolean'];
  next_payment_attempt: Scalars['Int'];
  number: Scalars['String'];
  object: Scalars['String'];
  on_behalf_of: Scalars['String'];
  paid: Scalars['Boolean'];
  paid_out_of_band: Scalars['Boolean'];
  payment_intent: Scalars['String'];
  payment_settings: InvoicePaymentSettings;
  period_end: Scalars['Int'];
  period_start: Scalars['Int'];
  post_payment_credit_notes_amount: Scalars['Int'];
  pre_payment_credit_notes_amount: Scalars['Int'];
  quote: Scalars['String'];
  receipt_number: Scalars['String'];
  starting_balance: Scalars['Int'];
  statement_descriptor: Scalars['String'];
  status: Scalars['String'];
  status_transitions: InvoiceStatusTransitions;
  subscription: Scalars['String'];
  subtotal: Scalars['Int'];
  tax: Scalars['String'];
  total: Scalars['Int'];
  total_discount_amounts: Array<Scalars['String']>;
  total_tax_amounts: Array<Scalars['String']>;
  transfer_data: Scalars['String'];
  webhooks_delivered_at: Scalars['String'];
};

export type InvoiceAddress = {
  __typename?: 'InvoiceAddress';
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  line1: Maybe<Scalars['String']>;
  line2: Maybe<Scalars['String']>;
  postal_code: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
};

export type InvoiceAutomaticTax = {
  __typename?: 'InvoiceAutomaticTax';
  enabled: Scalars['Boolean'];
  status: Scalars['String'];
};

export type InvoiceCustomerAddress = {
  __typename?: 'InvoiceCustomerAddress';
  city: Scalars['String'];
  country: Scalars['String'];
  line1: Scalars['String'];
  line2: Scalars['String'];
  postal_code: Scalars['String'];
  state: Scalars['String'];
};

export type InvoiceCustomerShipping = {
  __typename?: 'InvoiceCustomerShipping';
  address: InvoiceAddress;
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type InvoiceLineDate = {
  __typename?: 'InvoiceLineDate';
  amount: Scalars['Int'];
  currency: Scalars['String'];
  description: Scalars['String'];
  discount_amounts: Array<Scalars['String']>;
  discountable: Scalars['Boolean'];
  discounts: Array<Scalars['String']>;
  id: Scalars['String'];
  invoice_item: Scalars['String'];
  livemode: Scalars['Boolean'];
  object: Scalars['String'];
  period: InvoicePeriod;
  price: InvoicePrice;
  proration: Scalars['Boolean'];
  proration_details: InvoiceProrationDetails;
  quantity: Scalars['Int'];
  subscription: Scalars['String'];
  tax_amounts: Array<Scalars['String']>;
  tax_rates: Array<Scalars['String']>;
  type: Scalars['String'];
};

export type InvoiceLines = {
  __typename?: 'InvoiceLines';
  data: InvoiceLineDate;
  has_more: Scalars['Boolean'];
  object: Scalars['String'];
  url: Scalars['String'];
};

export type InvoicePaymentSettings = {
  __typename?: 'InvoicePaymentSettings';
  payment_method_options: Scalars['String'];
  payment_method_types: Scalars['String'];
};

export type InvoicePeriod = {
  __typename?: 'InvoicePeriod';
  end: Scalars['Int'];
  start: Scalars['Int'];
};

export type InvoicePrice = {
  __typename?: 'InvoicePrice';
  active: Scalars['Boolean'];
  billing_scheme: Scalars['String'];
  created: Scalars['Int'];
  currency: Scalars['String'];
  id: Scalars['String'];
  livemode: Scalars['Boolean'];
  lookup_key: Scalars['String'];
  nickname: Scalars['String'];
  object: Scalars['String'];
  product: Scalars['String'];
  recurring: Scalars['String'];
  tax_behavior: Scalars['String'];
  tiers_mode: Scalars['String'];
  transform_quantity: Scalars['String'];
  type: Scalars['String'];
  unit_amount: Scalars['Int'];
  unit_amount_decimal: Scalars['String'];
};

export type InvoiceProrationDetails = {
  __typename?: 'InvoiceProrationDetails';
  credited_items: Scalars['String'];
};

export type InvoiceStatusTransitions = {
  __typename?: 'InvoiceStatusTransitions';
  finalized_at: Scalars['String'];
  marked_uncollectible_at: Scalars['String'];
  paid_at: Scalars['String'];
  voided_at: Scalars['String'];
};

export type InvoicesPagination = {
  limit: InputMaybe<Scalars['Int']>;
  startAfterId: InputMaybe<Scalars['String']>;
};

export type InvoicesPayload = {
  __typename?: 'InvoicesPayload';
  hasMore: Scalars['Boolean'];
  items: Array<Invoice>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  cancelSubscription: Scalars['Boolean'];
  completeSignedUpload: Upload;
  confirmSignupEmailAddress: Scalars['Boolean'];
  contactSupport: Scalars['Boolean'];
  createApiKey: ApiKey;
  createCheckoutSession: CreateCheckoutSessionPayload;
  createEnhancedImage: EnhancedImage;
  createExport: CreateExportPayload;
  createFolder: Folder;
  createPortalSession: CreatePortalSessionPayload;
  createSignedUploadUrl: CreateSignedUploadUrlPayload;
  createUploadFromUrl: Upload;
  createWebhook: Maybe<Webhook>;
  deleteApiKey: Scalars['Boolean'];
  deleteFolder: Scalars['Boolean'];
  deleteMe: Scalars['Boolean'];
  deleteUploads: DeleteUploadsPayload;
  deleteWebhook: Scalars['Boolean'];
  deleteWorkflow: Scalars['Boolean'];
  login: User;
  refreshApiKey: ApiKey;
  requestResetPassword: Scalars['Boolean'];
  resendEmailConfirmation: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  signup: Scalars['Boolean'];
  updateEmailAddress: Scalars['Boolean'];
  updateFolder: Folder;
  updatePassword: Scalars['Boolean'];
  updateProfile: User;
  updateWebhook: Webhook;
  updateWorkflowSettings: Workflow;
  upsertEnhancedImageFeedback: Scalars['Boolean'];
  upsertWorkflow: Workflow;
  upsertWorkflowTriggers: Workflow;
};


export type MutationCancelSubscriptionArgs = {
  input: CancelSubscriptionInput;
};


export type MutationCompleteSignedUploadArgs = {
  id: Scalars['String'];
};


export type MutationConfirmSignupEmailAddressArgs = {
  input: ConfirmSignupEmailAddressInput;
};


export type MutationContactSupportArgs = {
  input: ContactSupportInput;
};


export type MutationCreateApiKeyArgs = {
  label: Scalars['String'];
};


export type MutationCreateCheckoutSessionArgs = {
  input: CreateCheckoutSessionInput;
};


export type MutationCreateEnhancedImageArgs = {
  input: CreateEnhancedImageInput;
  uploadId: Scalars['String'];
};


export type MutationCreateExportArgs = {
  uploadIds: Array<Scalars['String']>;
};


export type MutationCreateFolderArgs = {
  input: CreateFolderInput;
};


export type MutationCreatePortalSessionArgs = {
  input: CreatePortalSessionInput;
};


export type MutationCreateSignedUploadUrlArgs = {
  input: CreateSignedUploadUrlInput;
};


export type MutationCreateUploadFromUrlArgs = {
  input: CreateUploadFromUrlInput;
};


export type MutationCreateWebhookArgs = {
  input: CreateWebhookInput;
};


export type MutationDeleteApiKeyArgs = {
  id: Scalars['String'];
};


export type MutationDeleteFolderArgs = {
  id: Scalars['String'];
};


export type MutationDeleteMeArgs = {
  input: DeleteMeInput;
};


export type MutationDeleteUploadsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDeleteWebhookArgs = {
  id: Scalars['String'];
};


export type MutationDeleteWorkflowArgs = {
  workflowId: Scalars['String'];
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationRefreshApiKeyArgs = {
  id: Scalars['String'];
};


export type MutationRequestResetPasswordArgs = {
  input: RequestResetPasswordInput;
};


export type MutationResendEmailConfirmationArgs = {
  input: ResendEmailConfirmationInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationSignupArgs = {
  input: SignupInput;
};


export type MutationUpdateEmailAddressArgs = {
  input: UpdateEmailAddressInput;
};


export type MutationUpdateFolderArgs = {
  id: Scalars['String'];
  input: UpdateFolderInput;
};


export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUpdateWebhookArgs = {
  id: Scalars['String'];
  input: UpdateWebhookInput;
};


export type MutationUpdateWorkflowSettingsArgs = {
  input: UpsertWorkflowSettingsInput;
  workflowId: Scalars['String'];
};


export type MutationUpsertEnhancedImageFeedbackArgs = {
  enhancedImageId: Scalars['String'];
  input: UpsertEnhancedImageFeedbackInput;
};


export type MutationUpsertWorkflowArgs = {
  input: WorkflowUpsertInput;
};


export type MutationUpsertWorkflowTriggersArgs = {
  input: UpsertWorkflowTriggersInput;
  workflowId: Scalars['String'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Maybe<Scalars['String']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  billing_details: PaymentMethodBillingDetails;
  card: PaymentMethodCard;
  created: Scalars['Int'];
  customer: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  livemode: Scalars['Boolean'];
  object: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
};

export type PaymentMethodAddress = {
  __typename?: 'PaymentMethodAddress';
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  line1: Maybe<Scalars['String']>;
  line2: Maybe<Scalars['String']>;
  postal_code: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
};

export type PaymentMethodBillingDetails = {
  __typename?: 'PaymentMethodBillingDetails';
  address: PaymentMethodAddress;
  email: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
};

export type PaymentMethodCard = {
  __typename?: 'PaymentMethodCard';
  brand: Maybe<Scalars['String']>;
  checks: PaymentMethodChecks;
  country: Maybe<Scalars['String']>;
  exp_month: Scalars['Int'];
  exp_year: Scalars['Int'];
  fingerprint: Maybe<Scalars['String']>;
  funding: Maybe<Scalars['String']>;
  generated_from: Maybe<Scalars['String']>;
  last4: Maybe<Scalars['String']>;
  networks: PaymentMethodNetworks;
  three_d_secure_usage: PaymentMethodThreeDSecureUsage;
  wallet: Maybe<Scalars['String']>;
};

export type PaymentMethodChecks = {
  __typename?: 'PaymentMethodChecks';
  address_line1_check: Maybe<Scalars['String']>;
  address_postal_code_check: Maybe<Scalars['String']>;
  cvc_check: Maybe<Scalars['String']>;
};

export type PaymentMethodNetworks = {
  __typename?: 'PaymentMethodNetworks';
  available: Maybe<Scalars['String']>;
  preferred: Maybe<Scalars['String']>;
};

export type PaymentMethodThreeDSecureUsage = {
  __typename?: 'PaymentMethodThreeDSecureUsage';
  supported: Scalars['Boolean'];
};

export type PaymentMethodsPayload = {
  __typename?: 'PaymentMethodsPayload';
  items: Array<PaymentMethod>;
};

export type Plan = {
  __typename?: 'Plan';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Profile = {
  __typename?: 'Profile';
  avatarUrl: Maybe<Scalars['String']>;
  companyName: Maybe<Scalars['String']>;
  companyRole: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  dateOfBirth: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName: Maybe<Scalars['String']>;
  salutation: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  account: Maybe<Account>;
  apiKeys: Array<ApiKey>;
  apiKeysUsage: ApiKeysUsagePayload;
  credits: CreditsPayload;
  enhancedImage: Maybe<EnhancedImage>;
  enhancedImageCompleted: EnhancedImage;
  folder: Maybe<Folder>;
  folderBreadcrumb: Array<FolderBreadcrumbItem>;
  folders: QueryFoldersConnection;
  invoices: InvoicesPayload;
  me: User;
  paymentMethods: PaymentMethodsPayload;
  tasksList: TasksListPayload;
  upload: Maybe<Upload>;
  uploads: QueryUploadsConnection;
  version: Scalars['String'];
  webhook: Maybe<Webhook>;
  webhookLogs: WebhookLogsPayload;
  webhooks: Array<Webhook>;
  workflow: Maybe<Workflow>;
  workflows: QueryWorkflowsConnection;
};


export type QueryApiKeysUsageArgs = {
  apiKeyId: InputMaybe<Scalars['String']>;
  date: InputMaybe<Scalars['String']>;
};


export type QueryEnhancedImageArgs = {
  id: Scalars['String'];
};


export type QueryEnhancedImageCompletedArgs = {
  id: Scalars['String'];
};


export type QueryFolderArgs = {
  id: Scalars['String'];
};


export type QueryFolderBreadcrumbArgs = {
  id: Scalars['String'];
};


export type QueryFoldersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<FoldersFilterInput>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
};


export type QueryInvoicesArgs = {
  pagination: InputMaybe<InvoicesPagination>;
};


export type QueryUploadArgs = {
  id: Scalars['String'];
};


export type QueryUploadsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  filter: InputMaybe<UploadsFilterInput>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
};


export type QueryWebhookArgs = {
  id: Scalars['String'];
};


export type QueryWebhookLogsArgs = {
  filter: InputMaybe<WebhookLogsFilter>;
  webhookId: Scalars['String'];
};


export type QueryWorkflowArgs = {
  id: Scalars['String'];
};


export type QueryWorkflowsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
};

export type QueryFoldersConnection = {
  __typename?: 'QueryFoldersConnection';
  edges: Array<Maybe<QueryFoldersConnectionEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type QueryFoldersConnectionEdge = {
  __typename?: 'QueryFoldersConnectionEdge';
  cursor: Scalars['String'];
  node: Folder;
};

export type QueryUploadsConnection = {
  __typename?: 'QueryUploadsConnection';
  edges: Array<Maybe<QueryUploadsConnectionEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type QueryUploadsConnectionEdge = {
  __typename?: 'QueryUploadsConnectionEdge';
  cursor: Scalars['String'];
  node: Upload;
};

export type QueryWorkflowsConnection = {
  __typename?: 'QueryWorkflowsConnection';
  edges: Array<Maybe<QueryWorkflowsConnectionEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type QueryWorkflowsConnectionEdge = {
  __typename?: 'QueryWorkflowsConnectionEdge';
  cursor: Scalars['String'];
  node: Workflow;
};

export type RequestResetPasswordInput = {
  email: Scalars['String'];
  url: Scalars['String'];
};

export type ResendEmailConfirmationInput = {
  email: Scalars['String'];
  url: Scalars['String'];
};

export type ResetPasswordInput = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};

export type SignupInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  foundViesusThrough: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  secret: Scalars['String'];
  url: InputMaybe<Scalars['String']>;
};

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  application_fee_percent: Maybe<Scalars['Int']>;
  billing_cycle_anchor: Scalars['Int'];
  cancel_at: Maybe<Scalars['Int']>;
  cancel_at_period_end: Scalars['Boolean'];
  canceled_at: Maybe<Scalars['Int']>;
  collection_method: Maybe<Scalars['String']>;
  created: Scalars['Int'];
  current_period_end: Scalars['Int'];
  current_period_start: Scalars['Int'];
  customer: Maybe<Scalars['String']>;
  days_until_due: Maybe<Scalars['Int']>;
  default_payment_method: Scalars['String'];
  ended_at: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['String']>;
  latest_invoice: Scalars['String'];
  livemode: Scalars['Boolean'];
  next_pending_invoice_item_invoice: Maybe<Scalars['Int']>;
  object: Maybe<Scalars['String']>;
  start_date: Scalars['Int'];
  status: Maybe<Scalars['String']>;
};

export type TasksListPayload = {
  __typename?: 'TasksListPayload';
  _id: Scalars['String'];
  completed: Array<EnhancedImage>;
  hash: Scalars['String'];
  inProgress: Array<EnhancedImage>;
};

export type UpdateEmailAddressInput = {
  email: Scalars['String'];
};

export type UpdateFolderInput = {
  name: Scalars['String'];
};

export type UpdatePasswordInput = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type UpdateProfileInput = {
  companyName: InputMaybe<Scalars['String']>;
  companyRole: InputMaybe<Scalars['String']>;
  country: InputMaybe<Scalars['String']>;
  dateOfBirth: InputMaybe<Scalars['String']>;
  firstName: InputMaybe<Scalars['String']>;
  lastName: InputMaybe<Scalars['String']>;
  salutation: InputMaybe<Scalars['String']>;
};

export type UpdateWebhookInput = {
  url: Scalars['String'];
};

export type Upload = {
  __typename?: 'Upload';
  EnhancedImages: Array<EnhancedImage>;
  duration: Maybe<Scalars['Int']>;
  extension: Scalars['String'];
  filename: Scalars['String'];
  filesize: Maybe<Scalars['Int']>;
  finishedAt: Maybe<Scalars['Date']>;
  folderId: Maybe<Scalars['String']>;
  height: Scalars['Int'];
  id: Scalars['ID'];
  lastSuccesfulEnhancedImage: Maybe<EnhancedImage>;
  mimetype: Scalars['String'];
  originUrl: Maybe<Scalars['String']>;
  originalFilename: Scalars['String'];
  startedAt: Maybe<Scalars['Date']>;
  status: UploadStatus;
  url: Maybe<Scalars['String']>;
  width: Scalars['Int'];
};

export enum UploadStatus {
  Deleted = 'DELETED',
  Error = 'ERROR',
  UploadFinished = 'UPLOAD_FINISHED',
  UploadPending = 'UPLOAD_PENDING'
}

export type UploadsFilterInput = {
  folderId: InputMaybe<Scalars['String']>;
  ids: InputMaybe<Array<Scalars['String']>>;
  skip: InputMaybe<Scalars['Int']>;
  take: InputMaybe<Scalars['Int']>;
  type: InputMaybe<UploadsFilterInputType>;
};

export enum UploadsFilterInputType {
  ApiOnly = 'API_ONLY',
  WebOnly = 'WEB_ONLY'
}

export type UpsertEnhancedImageFeedbackInput = {
  allowSharing: InputMaybe<Scalars['Boolean']>;
  comment: Scalars['String'];
  email: Scalars['String'];
  score: Scalars['Int'];
};

export type UpsertWorkflowSettingsInput = {
  name: Scalars['String'];
};

export type UpsertWorkflowTriggersInput = {
  triggers: Array<WorkflowTriggerInput>;
};

export type User = {
  __typename?: 'User';
  Profile: Maybe<Profile>;
  activePlan: Scalars['String'];
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  id: Scalars['ID'];
  isOAuth: Scalars['Boolean'];
  issuerId: Maybe<Scalars['String']>;
  mustValidateUrgently: Scalars['Boolean'];
  status: UserStatus;
  updatedAt: Scalars['Date'];
};

export enum UserStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING'
}

export type Webhook = {
  __typename?: 'Webhook';
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  secret: Scalars['String'];
  updatedAt: Maybe<Scalars['Date']>;
  url: Scalars['String'];
};

export type WebhookLogsFilter = {
  skip: InputMaybe<Scalars['Int']>;
  /** Use SUCCEEDED or FAILED to filter between statuses, leave empty to return both. */
  status: InputMaybe<WebhookResponseStatus>;
  take: InputMaybe<Scalars['Int']>;
};

export type WebhookLogsPayload = {
  __typename?: 'WebhookLogsPayload';
  items: Array<WebhookResponseLog>;
  total: Scalars['Int'];
};

export type WebhookResponseLog = {
  __typename?: 'WebhookResponseLog';
  createdAt: Scalars['Date'];
  event: Scalars['String'];
  id: Scalars['ID'];
  responseBody: Scalars['String'];
  responseStatusCode: Scalars['Int'];
  status: WebhookResponseStatus;
  webhookData: Scalars['PrismaJson'];
};

export enum WebhookResponseStatus {
  Failed = 'FAILED',
  Succeeded = 'SUCCEEDED'
}

export type Workflow = {
  __typename?: 'Workflow';
  Parameters: Maybe<WorkflowParameters>;
  Triggers: Array<WorkflowTrigger>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type WorkflowParameters = {
  __typename?: 'WorkflowParameters';
  artifactsDetectionRemoval: Maybe<EnhancedImageParametersArtifactsDetectionRemoval>;
  enhanceFaceDetails: Maybe<EnhancedImageParametersEnhanceFaceDetails>;
  id: Scalars['String'];
  preset: Maybe<EnhancedImageParametersPreset>;
  upscalingCustomWidth: Maybe<Scalars['Int']>;
  upscalingFactor: Maybe<EnhancedImageParametersUpscalingFactor>;
};

export type WorkflowTrigger = {
  __typename?: 'WorkflowTrigger';
  InputFolder: Maybe<Folder>;
  OutputFolder: Maybe<Folder>;
  Workflow: Workflow;
  id: Scalars['String'];
};

export type WorkflowTriggerInput = {
  inputFolderId: Scalars['String'];
  outputFolderId: Scalars['String'];
};

export type WorkflowUpsertInput = {
  id: InputMaybe<Scalars['String']>;
  name: InputMaybe<Scalars['String']>;
  parameters: InputMaybe<WorkflowUpsertParametersInput>;
};

export type WorkflowUpsertParametersInput = {
  artifactsDetectionRemoval: InputMaybe<EnhancedImageParametersArtifactsDetectionRemoval>;
  enhanceFaceDetails: InputMaybe<EnhancedImageParametersEnhanceFaceDetails>;
  preset: InputMaybe<EnhancedImageParametersPreset>;
  upscalingCustomWidth: InputMaybe<Scalars['Int']>;
  upscalingFactor: InputMaybe<EnhancedImageParametersUpscalingFactor>;
};

export type UploadFieldsFragment = { __typename?: 'Upload', id: string, url: string | null, filename: string, originalFilename: string, height: number, width: number, mimetype: string, startedAt: any | null, finishedAt: any | null, folderId: string | null, lastSuccesfulEnhancedImage: { __typename?: 'EnhancedImage', id: string, fullUrl: string | null, status: EnhancedImageStatus, viesusDuration: number | null, errorCode: string | null, uploadId: string, EnhancedImageParameters: { __typename?: 'EnhancedImageParameters', id: string, defaultImageEnhancement: EnhancedImageParametersDefaultImageEnhancement | null, upscalingFactor: EnhancedImageParametersUpscalingFactor | null, upscalingCustomWidth: number | null, upscalingCustomDPI: number | null, artifactsDetectionRemoval: EnhancedImageParametersArtifactsDetectionRemoval | null, enhanceFaceDetails: EnhancedImageParametersEnhanceFaceDetails | null, preset: EnhancedImageParametersPreset | null }, Workflow: { __typename?: 'Workflow', id: string, name: string } | null, EnhancedImageFeedback: { __typename?: 'EnhancedImageFeedback', id: string, score: number, comment: string } | null } | null };

export type EnhancedImageFieldsFragment = { __typename?: 'EnhancedImage', id: string, fullUrl: string | null, status: EnhancedImageStatus, viesusDuration: number | null, errorCode: string | null, uploadId: string, EnhancedImageParameters: { __typename?: 'EnhancedImageParameters', id: string, defaultImageEnhancement: EnhancedImageParametersDefaultImageEnhancement | null, upscalingFactor: EnhancedImageParametersUpscalingFactor | null, upscalingCustomWidth: number | null, upscalingCustomDPI: number | null, artifactsDetectionRemoval: EnhancedImageParametersArtifactsDetectionRemoval | null, enhanceFaceDetails: EnhancedImageParametersEnhanceFaceDetails | null, preset: EnhancedImageParametersPreset | null }, Workflow: { __typename?: 'Workflow', id: string, name: string } | null, EnhancedImageFeedback: { __typename?: 'EnhancedImageFeedback', id: string, score: number, comment: string } | null };

export type FolderFieldsFragment = { __typename?: 'Folder', id: string, name: string, uploadsCount: number, parentFolderId: string | null, InputWorkflowTriggers: Array<{ __typename?: 'WorkflowTrigger', id: string }> };

export type WorkflowFieldsFragment = { __typename?: 'Workflow', id: string, name: string, Triggers: Array<{ __typename?: 'WorkflowTrigger', id: string, InputFolder: { __typename?: 'Folder', id: string, name: string, uploadsCount: number, parentFolderId: string | null, InputWorkflowTriggers: Array<{ __typename?: 'WorkflowTrigger', id: string }> } | null, OutputFolder: { __typename?: 'Folder', id: string, name: string, uploadsCount: number, parentFolderId: string | null, InputWorkflowTriggers: Array<{ __typename?: 'WorkflowTrigger', id: string }> } | null }>, Parameters: { __typename?: 'WorkflowParameters', id: string, upscalingFactor: EnhancedImageParametersUpscalingFactor | null, upscalingCustomWidth: number | null, artifactsDetectionRemoval: EnhancedImageParametersArtifactsDetectionRemoval | null, enhanceFaceDetails: EnhancedImageParametersEnhanceFaceDetails | null, preset: EnhancedImageParametersPreset | null } | null };

export type ApiKeyFieldsFragment = { __typename?: 'ApiKey', id: string | null, label: string | null, key: string | null };

export type ApiKeysQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiKeysQuery = { apiKeys: Array<{ __typename?: 'ApiKey', id: string | null, label: string | null, key: string | null }> };

export type CreateApiKeyMutationVariables = Exact<{
  label: Scalars['String'];
}>;


export type CreateApiKeyMutation = { createApiKey: { __typename?: 'ApiKey', id: string | null, label: string | null, key: string | null } };

export type DeleteApiKeyMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteApiKeyMutation = { deleteApiKey: boolean };

export type RefreshApiKeyMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RefreshApiKeyMutation = { refreshApiKey: { __typename?: 'ApiKey', id: string | null, label: string | null, key: string | null } };

export type ApiKeysUsageQueryVariables = Exact<{
  apiKeyId: InputMaybe<Scalars['String']>;
  date: InputMaybe<Scalars['String']>;
}>;


export type ApiKeysUsageQuery = { apiKeysUsage: { __typename?: 'ApiKeysUsagePayload', _id: string, uploadsFinished: number, enhancementsCreated: number, creditsUsed: number, averageEnhancementDuration: number | null } };

export type UserFieldsFragment = { __typename?: 'User', email: string, id: string, status: UserStatus, mustValidateUrgently: boolean, isOAuth: boolean, activePlan: string, Profile: { __typename?: 'Profile', id: string, avatarUrl: string | null, firstName: string | null, lastName: string | null, salutation: string | null, dateOfBirth: string | null, country: string | null, companyName: string | null, companyRole: string | null } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { me: { __typename?: 'User', email: string, id: string, status: UserStatus, mustValidateUrgently: boolean, isOAuth: boolean, activePlan: string, Profile: { __typename?: 'Profile', id: string, avatarUrl: string | null, firstName: string | null, lastName: string | null, salutation: string | null, dateOfBirth: string | null, country: string | null, companyName: string | null, companyRole: string | null } | null } };

export type UpdateProfileMutationVariables = Exact<{
  input: UpdateProfileInput;
}>;


export type UpdateProfileMutation = { updateProfile: { __typename?: 'User', email: string, id: string, status: UserStatus, mustValidateUrgently: boolean, isOAuth: boolean, activePlan: string, Profile: { __typename?: 'Profile', id: string, avatarUrl: string | null, firstName: string | null, lastName: string | null, salutation: string | null, dateOfBirth: string | null, country: string | null, companyName: string | null, companyRole: string | null } | null } };

export type DeleteMeMutationVariables = Exact<{
  input: DeleteMeInput;
}>;


export type DeleteMeMutation = { deleteMe: boolean };

export type UpdatePasswordMutationVariables = Exact<{
  input: UpdatePasswordInput;
}>;


export type UpdatePasswordMutation = { updatePassword: boolean };

export type UpdateEmailAddressMutationVariables = Exact<{
  input: UpdateEmailAddressInput;
}>;


export type UpdateEmailAddressMutation = { updateEmailAddress: boolean };

export type SignupMutationVariables = Exact<{
  input: SignupInput;
}>;


export type SignupMutation = { signup: boolean };

export type ConfirmSignupEmailAddressMutationVariables = Exact<{
  input: ConfirmSignupEmailAddressInput;
}>;


export type ConfirmSignupEmailAddressMutation = { confirmSignupEmailAddress: boolean };

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = { login: { __typename?: 'User', id: string, email: string } };

export type RequestResetPasswordMutationVariables = Exact<{
  input: RequestResetPasswordInput;
}>;


export type RequestResetPasswordMutation = { requestResetPassword: boolean };

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { resetPassword: boolean };

export type ResendEmailConfirmationMutationVariables = Exact<{
  input: ResendEmailConfirmationInput;
}>;


export type ResendEmailConfirmationMutation = { resendEmailConfirmation: boolean };

export type ContactSupportMutationVariables = Exact<{
  input: ContactSupportInput;
}>;


export type ContactSupportMutation = { contactSupport: boolean };

export type AccountQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountQuery = { account: { __typename?: 'Account', id: string, AccountSubscription: { __typename?: 'AccountSubscription', id: string, Plan: { __typename?: 'Plan', id: string, name: string } }, stripeSubscription: { __typename?: 'StripeSubscription', cancel_at_period_end: boolean, current_period_end: number } | null } | null };

export type CreditsQueryVariables = Exact<{ [key: string]: never; }>;


export type CreditsQuery = { credits: { __typename?: 'CreditsPayload', id: string, periodStart: any, periodEnd: any | null, usedCreditsThisPeriod: number, remainingCreditsThisPeriod: number | null } };

export type CreateEnhancedImageMutationVariables = Exact<{
  uploadId: Scalars['String'];
  input: CreateEnhancedImageInput;
}>;


export type CreateEnhancedImageMutation = { createEnhancedImage: { __typename?: 'EnhancedImage', id: string, fullUrl: string | null, status: EnhancedImageStatus, viesusDuration: number | null, errorCode: string | null, uploadId: string, EnhancedImageParameters: { __typename?: 'EnhancedImageParameters', id: string, defaultImageEnhancement: EnhancedImageParametersDefaultImageEnhancement | null, upscalingFactor: EnhancedImageParametersUpscalingFactor | null, upscalingCustomWidth: number | null, upscalingCustomDPI: number | null, artifactsDetectionRemoval: EnhancedImageParametersArtifactsDetectionRemoval | null, enhanceFaceDetails: EnhancedImageParametersEnhanceFaceDetails | null, preset: EnhancedImageParametersPreset | null }, Workflow: { __typename?: 'Workflow', id: string, name: string } | null, EnhancedImageFeedback: { __typename?: 'EnhancedImageFeedback', id: string, score: number, comment: string } | null } };

export type EnhancedImageQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type EnhancedImageQuery = { enhancedImage: { __typename?: 'EnhancedImage', id: string, fullUrl: string | null, status: EnhancedImageStatus, viesusDuration: number | null, errorCode: string | null, uploadId: string, EnhancedImageParameters: { __typename?: 'EnhancedImageParameters', id: string, defaultImageEnhancement: EnhancedImageParametersDefaultImageEnhancement | null, upscalingFactor: EnhancedImageParametersUpscalingFactor | null, upscalingCustomWidth: number | null, upscalingCustomDPI: number | null, artifactsDetectionRemoval: EnhancedImageParametersArtifactsDetectionRemoval | null, enhanceFaceDetails: EnhancedImageParametersEnhanceFaceDetails | null, preset: EnhancedImageParametersPreset | null }, Workflow: { __typename?: 'Workflow', id: string, name: string } | null, EnhancedImageFeedback: { __typename?: 'EnhancedImageFeedback', id: string, score: number, comment: string } | null } | null };

export type EnhancedImageWithFeedbackQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type EnhancedImageWithFeedbackQuery = { enhancedImage: { __typename?: 'EnhancedImage', id: string, fullUrl: string | null, status: EnhancedImageStatus, viesusDuration: number | null, errorCode: string | null, uploadId: string, EnhancedImageFeedback: { __typename?: 'EnhancedImageFeedback', id: string, score: number, comment: string } | null, EnhancedImageParameters: { __typename?: 'EnhancedImageParameters', id: string, defaultImageEnhancement: EnhancedImageParametersDefaultImageEnhancement | null, upscalingFactor: EnhancedImageParametersUpscalingFactor | null, upscalingCustomWidth: number | null, upscalingCustomDPI: number | null, artifactsDetectionRemoval: EnhancedImageParametersArtifactsDetectionRemoval | null, enhanceFaceDetails: EnhancedImageParametersEnhanceFaceDetails | null, preset: EnhancedImageParametersPreset | null }, Workflow: { __typename?: 'Workflow', id: string, name: string } | null } | null };

export type EnhancedImageCompletedQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type EnhancedImageCompletedQuery = { enhancedImageCompleted: { __typename?: 'EnhancedImage', id: string, fullUrl: string | null, status: EnhancedImageStatus, viesusDuration: number | null, errorCode: string | null, uploadId: string, EnhancedImageParameters: { __typename?: 'EnhancedImageParameters', id: string, defaultImageEnhancement: EnhancedImageParametersDefaultImageEnhancement | null, upscalingFactor: EnhancedImageParametersUpscalingFactor | null, upscalingCustomWidth: number | null, upscalingCustomDPI: number | null, artifactsDetectionRemoval: EnhancedImageParametersArtifactsDetectionRemoval | null, enhanceFaceDetails: EnhancedImageParametersEnhanceFaceDetails | null, preset: EnhancedImageParametersPreset | null }, Workflow: { __typename?: 'Workflow', id: string, name: string } | null, EnhancedImageFeedback: { __typename?: 'EnhancedImageFeedback', id: string, score: number, comment: string } | null } };

export type UpsertEnhancedImageFeedbackMutationVariables = Exact<{
  enhancedImageId: Scalars['String'];
  input: UpsertEnhancedImageFeedbackInput;
}>;


export type UpsertEnhancedImageFeedbackMutation = { upsertEnhancedImageFeedback: boolean };

export type CreateExportMutationVariables = Exact<{
  uploadIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateExportMutation = { createExport: { __typename?: 'CreateExportPayload', downloadUrl: string | null, filename: string | null, enhancedImagesCount: number, unEnhancedImagesCount: number, exportCreatedAsync: boolean } };

export type FoldersQueryVariables = Exact<{
  first: Scalars['Int'];
  after: InputMaybe<Scalars['String']>;
  filter: InputMaybe<FoldersFilterInput>;
}>;


export type FoldersQuery = { folders: { __typename?: 'QueryFoldersConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean }, edges: Array<{ __typename?: 'QueryFoldersConnectionEdge', cursor: string, node: { __typename?: 'Folder', id: string, name: string, uploadsCount: number, parentFolderId: string | null, InputWorkflowTriggers: Array<{ __typename?: 'WorkflowTrigger', id: string }> } } | null> } };

export type WorkflowTriggerFieldsFragment = { __typename?: 'WorkflowTrigger', id: string, Workflow: { __typename?: 'Workflow', id: string, name: string } };

export type FolderQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FolderQuery = { folder: { __typename?: 'Folder', id: string, name: string, uploadsCount: number, parentFolderId: string | null, InputWorkflowTriggers: Array<{ __typename?: 'WorkflowTrigger', id: string, Workflow: { __typename?: 'Workflow', id: string, name: string } }>, OutputWorkflowTriggers: Array<{ __typename?: 'WorkflowTrigger', id: string, Workflow: { __typename?: 'Workflow', id: string, name: string } }> } | null, folderBreadcrumb: Array<{ __typename?: 'FolderBreadcrumbItem', id: string, name: string }> };

export type CreateFolderMutationVariables = Exact<{
  input: CreateFolderInput;
}>;


export type CreateFolderMutation = { createFolder: { __typename?: 'Folder', id: string, name: string, uploadsCount: number, parentFolderId: string | null, InputWorkflowTriggers: Array<{ __typename?: 'WorkflowTrigger', id: string }> } };

export type UpdateFolderMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateFolderInput;
}>;


export type UpdateFolderMutation = { updateFolder: { __typename?: 'Folder', id: string, name: string, uploadsCount: number, parentFolderId: string | null, InputWorkflowTriggers: Array<{ __typename?: 'WorkflowTrigger', id: string }> } };

export type DeleteFolderMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteFolderMutation = { deleteFolder: boolean };

export type CreatePortalSessionMutationVariables = Exact<{
  input: CreatePortalSessionInput;
}>;


export type CreatePortalSessionMutation = { createPortalSession: { __typename?: 'CreatePortalSessionPayload', url: string } };

export type CreateCheckoutSessionMutationVariables = Exact<{
  input: CreateCheckoutSessionInput;
}>;


export type CreateCheckoutSessionMutation = { createCheckoutSession: { __typename?: 'CreateCheckoutSessionPayload', url: string } };

export type PaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type PaymentMethodsQuery = { paymentMethods: { __typename?: 'PaymentMethodsPayload', items: Array<{ __typename?: 'PaymentMethod', card: { __typename?: 'PaymentMethodCard', last4: string | null, exp_month: number, exp_year: number, brand: string | null }, billing_details: { __typename?: 'PaymentMethodBillingDetails', name: string | null, address: { __typename?: 'PaymentMethodAddress', line1: string | null, line2: string | null, state: string | null, postal_code: string | null, country: string | null, city: string | null } } }> } };

export type InvoicesQueryVariables = Exact<{ [key: string]: never; }>;


export type InvoicesQuery = { invoices: { __typename?: 'InvoicesPayload', hasMore: boolean, items: Array<{ __typename?: 'Invoice', id: string, invoice_pdf: string, amount_due: number, amount_remaining: number, period_start: number, period_end: number, currency: string }> } };

export type CancelSubscriptionMutationVariables = Exact<{
  input: CancelSubscriptionInput;
}>;


export type CancelSubscriptionMutation = { cancelSubscription: boolean };

export type TasksListQueryVariables = Exact<{ [key: string]: never; }>;


export type TasksListQuery = { tasksList: { __typename?: 'TasksListPayload', _id: string, hash: string, inProgress: Array<{ __typename?: 'EnhancedImage', id: string, fullUrl: string | null, status: EnhancedImageStatus, viesusDuration: number | null, errorCode: string | null, uploadId: string, EnhancedImageParameters: { __typename?: 'EnhancedImageParameters', id: string, defaultImageEnhancement: EnhancedImageParametersDefaultImageEnhancement | null, upscalingFactor: EnhancedImageParametersUpscalingFactor | null, upscalingCustomWidth: number | null, upscalingCustomDPI: number | null, artifactsDetectionRemoval: EnhancedImageParametersArtifactsDetectionRemoval | null, enhanceFaceDetails: EnhancedImageParametersEnhanceFaceDetails | null, preset: EnhancedImageParametersPreset | null }, Workflow: { __typename?: 'Workflow', id: string, name: string } | null, EnhancedImageFeedback: { __typename?: 'EnhancedImageFeedback', id: string, score: number, comment: string } | null }>, completed: Array<{ __typename?: 'EnhancedImage', id: string, fullUrl: string | null, status: EnhancedImageStatus, viesusDuration: number | null, errorCode: string | null, uploadId: string, EnhancedImageParameters: { __typename?: 'EnhancedImageParameters', id: string, defaultImageEnhancement: EnhancedImageParametersDefaultImageEnhancement | null, upscalingFactor: EnhancedImageParametersUpscalingFactor | null, upscalingCustomWidth: number | null, upscalingCustomDPI: number | null, artifactsDetectionRemoval: EnhancedImageParametersArtifactsDetectionRemoval | null, enhanceFaceDetails: EnhancedImageParametersEnhanceFaceDetails | null, preset: EnhancedImageParametersPreset | null }, Workflow: { __typename?: 'Workflow', id: string, name: string } | null, EnhancedImageFeedback: { __typename?: 'EnhancedImageFeedback', id: string, score: number, comment: string } | null }> } };

export type CreateSignedUploadUrlMutationVariables = Exact<{
  input: CreateSignedUploadUrlInput;
}>;


export type CreateSignedUploadUrlMutation = { createSignedUploadUrl: { __typename?: 'CreateSignedUploadUrlPayload', uploadUrl: string, uploadId: string } };

export type CompleteSignedUploadMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type CompleteSignedUploadMutation = { completeSignedUpload: { __typename?: 'Upload', id: string, url: string | null, filename: string, originalFilename: string, height: number, width: number, mimetype: string, startedAt: any | null, finishedAt: any | null, folderId: string | null, lastSuccesfulEnhancedImage: { __typename?: 'EnhancedImage', id: string, fullUrl: string | null, status: EnhancedImageStatus, viesusDuration: number | null, errorCode: string | null, uploadId: string, EnhancedImageParameters: { __typename?: 'EnhancedImageParameters', id: string, defaultImageEnhancement: EnhancedImageParametersDefaultImageEnhancement | null, upscalingFactor: EnhancedImageParametersUpscalingFactor | null, upscalingCustomWidth: number | null, upscalingCustomDPI: number | null, artifactsDetectionRemoval: EnhancedImageParametersArtifactsDetectionRemoval | null, enhanceFaceDetails: EnhancedImageParametersEnhanceFaceDetails | null, preset: EnhancedImageParametersPreset | null }, Workflow: { __typename?: 'Workflow', id: string, name: string } | null, EnhancedImageFeedback: { __typename?: 'EnhancedImageFeedback', id: string, score: number, comment: string } | null } | null } };

export type UploadsQueryVariables = Exact<{
  ids: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  folderId: InputMaybe<Scalars['String']>;
  after: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
}>;


export type UploadsQuery = { uploads: { __typename?: 'QueryUploadsConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean }, edges: Array<{ __typename?: 'QueryUploadsConnectionEdge', cursor: string, node: { __typename?: 'Upload', id: string, url: string | null, filename: string, originalFilename: string, height: number, width: number, mimetype: string, startedAt: any | null, finishedAt: any | null, folderId: string | null, lastSuccesfulEnhancedImage: { __typename?: 'EnhancedImage', id: string, fullUrl: string | null, status: EnhancedImageStatus, viesusDuration: number | null, errorCode: string | null, uploadId: string, EnhancedImageParameters: { __typename?: 'EnhancedImageParameters', id: string, defaultImageEnhancement: EnhancedImageParametersDefaultImageEnhancement | null, upscalingFactor: EnhancedImageParametersUpscalingFactor | null, upscalingCustomWidth: number | null, upscalingCustomDPI: number | null, artifactsDetectionRemoval: EnhancedImageParametersArtifactsDetectionRemoval | null, enhanceFaceDetails: EnhancedImageParametersEnhanceFaceDetails | null, preset: EnhancedImageParametersPreset | null }, Workflow: { __typename?: 'Workflow', id: string, name: string } | null, EnhancedImageFeedback: { __typename?: 'EnhancedImageFeedback', id: string, score: number, comment: string } | null } | null } } | null> } };

export type UploadQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type UploadQuery = { upload: { __typename?: 'Upload', id: string, url: string | null, filename: string, originalFilename: string, height: number, width: number, mimetype: string, startedAt: any | null, finishedAt: any | null, folderId: string | null, lastSuccesfulEnhancedImage: { __typename?: 'EnhancedImage', id: string, fullUrl: string | null, status: EnhancedImageStatus, viesusDuration: number | null, errorCode: string | null, uploadId: string, EnhancedImageParameters: { __typename?: 'EnhancedImageParameters', id: string, defaultImageEnhancement: EnhancedImageParametersDefaultImageEnhancement | null, upscalingFactor: EnhancedImageParametersUpscalingFactor | null, upscalingCustomWidth: number | null, upscalingCustomDPI: number | null, artifactsDetectionRemoval: EnhancedImageParametersArtifactsDetectionRemoval | null, enhanceFaceDetails: EnhancedImageParametersEnhanceFaceDetails | null, preset: EnhancedImageParametersPreset | null }, Workflow: { __typename?: 'Workflow', id: string, name: string } | null, EnhancedImageFeedback: { __typename?: 'EnhancedImageFeedback', id: string, score: number, comment: string } | null } | null } | null };

export type DeleteUploadsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteUploadsMutation = { deleteUploads: { __typename?: 'DeleteUploadsPayload', deletedUploadIds: Array<string> } };

export type WorkflowsQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkflowsQuery = { workflows: { __typename?: 'QueryWorkflowsConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean }, edges: Array<{ __typename?: 'QueryWorkflowsConnectionEdge', node: { __typename?: 'Workflow', id: string, name: string, Triggers: Array<{ __typename?: 'WorkflowTrigger', id: string, InputFolder: { __typename?: 'Folder', id: string, name: string, uploadsCount: number, parentFolderId: string | null, InputWorkflowTriggers: Array<{ __typename?: 'WorkflowTrigger', id: string }> } | null, OutputFolder: { __typename?: 'Folder', id: string, name: string, uploadsCount: number, parentFolderId: string | null, InputWorkflowTriggers: Array<{ __typename?: 'WorkflowTrigger', id: string }> } | null }>, Parameters: { __typename?: 'WorkflowParameters', id: string, upscalingFactor: EnhancedImageParametersUpscalingFactor | null, upscalingCustomWidth: number | null, artifactsDetectionRemoval: EnhancedImageParametersArtifactsDetectionRemoval | null, enhanceFaceDetails: EnhancedImageParametersEnhanceFaceDetails | null, preset: EnhancedImageParametersPreset | null } | null } } | null> } };

export type WorkflowQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type WorkflowQuery = { workflow: { __typename?: 'Workflow', id: string, name: string, Triggers: Array<{ __typename?: 'WorkflowTrigger', id: string, InputFolder: { __typename?: 'Folder', id: string, name: string, uploadsCount: number, parentFolderId: string | null, InputWorkflowTriggers: Array<{ __typename?: 'WorkflowTrigger', id: string }> } | null, OutputFolder: { __typename?: 'Folder', id: string, name: string, uploadsCount: number, parentFolderId: string | null, InputWorkflowTriggers: Array<{ __typename?: 'WorkflowTrigger', id: string }> } | null }>, Parameters: { __typename?: 'WorkflowParameters', id: string, upscalingFactor: EnhancedImageParametersUpscalingFactor | null, upscalingCustomWidth: number | null, artifactsDetectionRemoval: EnhancedImageParametersArtifactsDetectionRemoval | null, enhanceFaceDetails: EnhancedImageParametersEnhanceFaceDetails | null, preset: EnhancedImageParametersPreset | null } | null } | null };

export type CreateWorkflowMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateWorkflowMutation = { upsertWorkflow: { __typename?: 'Workflow', id: string, name: string, Triggers: Array<{ __typename?: 'WorkflowTrigger', id: string, InputFolder: { __typename?: 'Folder', id: string, name: string, uploadsCount: number, parentFolderId: string | null, InputWorkflowTriggers: Array<{ __typename?: 'WorkflowTrigger', id: string }> } | null, OutputFolder: { __typename?: 'Folder', id: string, name: string, uploadsCount: number, parentFolderId: string | null, InputWorkflowTriggers: Array<{ __typename?: 'WorkflowTrigger', id: string }> } | null }>, Parameters: { __typename?: 'WorkflowParameters', id: string, upscalingFactor: EnhancedImageParametersUpscalingFactor | null, upscalingCustomWidth: number | null, artifactsDetectionRemoval: EnhancedImageParametersArtifactsDetectionRemoval | null, enhanceFaceDetails: EnhancedImageParametersEnhanceFaceDetails | null, preset: EnhancedImageParametersPreset | null } | null } };

export type UpsertWorkflowMutationVariables = Exact<{
  input: WorkflowUpsertInput;
}>;


export type UpsertWorkflowMutation = { upsertWorkflow: { __typename?: 'Workflow', id: string, name: string, Triggers: Array<{ __typename?: 'WorkflowTrigger', id: string, InputFolder: { __typename?: 'Folder', id: string, name: string, uploadsCount: number, parentFolderId: string | null, InputWorkflowTriggers: Array<{ __typename?: 'WorkflowTrigger', id: string }> } | null, OutputFolder: { __typename?: 'Folder', id: string, name: string, uploadsCount: number, parentFolderId: string | null, InputWorkflowTriggers: Array<{ __typename?: 'WorkflowTrigger', id: string }> } | null }>, Parameters: { __typename?: 'WorkflowParameters', id: string, upscalingFactor: EnhancedImageParametersUpscalingFactor | null, upscalingCustomWidth: number | null, artifactsDetectionRemoval: EnhancedImageParametersArtifactsDetectionRemoval | null, enhanceFaceDetails: EnhancedImageParametersEnhanceFaceDetails | null, preset: EnhancedImageParametersPreset | null } | null } };

export type DeleteWorkflowMutationVariables = Exact<{
  workflowId: Scalars['String'];
}>;


export type DeleteWorkflowMutation = { deleteWorkflow: boolean };

export type UpdateWorkflowSettingsMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpsertWorkflowSettingsInput;
}>;


export type UpdateWorkflowSettingsMutation = { updateWorkflowSettings: { __typename?: 'Workflow', id: string, name: string, Triggers: Array<{ __typename?: 'WorkflowTrigger', id: string, InputFolder: { __typename?: 'Folder', id: string, name: string, uploadsCount: number, parentFolderId: string | null, InputWorkflowTriggers: Array<{ __typename?: 'WorkflowTrigger', id: string }> } | null, OutputFolder: { __typename?: 'Folder', id: string, name: string, uploadsCount: number, parentFolderId: string | null, InputWorkflowTriggers: Array<{ __typename?: 'WorkflowTrigger', id: string }> } | null }>, Parameters: { __typename?: 'WorkflowParameters', id: string, upscalingFactor: EnhancedImageParametersUpscalingFactor | null, upscalingCustomWidth: number | null, artifactsDetectionRemoval: EnhancedImageParametersArtifactsDetectionRemoval | null, enhanceFaceDetails: EnhancedImageParametersEnhanceFaceDetails | null, preset: EnhancedImageParametersPreset | null } | null } };

export type UpsertWorkflowTriggersMutationVariables = Exact<{
  workflowId: Scalars['String'];
  input: UpsertWorkflowTriggersInput;
}>;


export type UpsertWorkflowTriggersMutation = { upsertWorkflowTriggers: { __typename?: 'Workflow', id: string, name: string, Triggers: Array<{ __typename?: 'WorkflowTrigger', id: string, InputFolder: { __typename?: 'Folder', id: string, name: string, uploadsCount: number, parentFolderId: string | null, InputWorkflowTriggers: Array<{ __typename?: 'WorkflowTrigger', id: string }> } | null, OutputFolder: { __typename?: 'Folder', id: string, name: string, uploadsCount: number, parentFolderId: string | null, InputWorkflowTriggers: Array<{ __typename?: 'WorkflowTrigger', id: string }> } | null }>, Parameters: { __typename?: 'WorkflowParameters', id: string, upscalingFactor: EnhancedImageParametersUpscalingFactor | null, upscalingCustomWidth: number | null, artifactsDetectionRemoval: EnhancedImageParametersArtifactsDetectionRemoval | null, enhanceFaceDetails: EnhancedImageParametersEnhanceFaceDetails | null, preset: EnhancedImageParametersPreset | null } | null } };

export const EnhancedImageFieldsFragmentDoc = gql`
    fragment EnhancedImageFields on EnhancedImage {
  id
  fullUrl
  status
  viesusDuration
  errorCode
  uploadId
  EnhancedImageParameters {
    id
    defaultImageEnhancement
    upscalingFactor
    upscalingCustomWidth
    upscalingCustomDPI
    artifactsDetectionRemoval
    enhanceFaceDetails
    preset
  }
  Workflow {
    id
    name
  }
  EnhancedImageFeedback {
    id
    score
    comment
  }
}
    `;
export const UploadFieldsFragmentDoc = gql`
    fragment UploadFields on Upload {
  id
  url
  filename
  originalFilename
  height
  width
  mimetype
  startedAt
  finishedAt
  folderId
  lastSuccesfulEnhancedImage {
    ...EnhancedImageFields
  }
}
    ${EnhancedImageFieldsFragmentDoc}`;
export const FolderFieldsFragmentDoc = gql`
    fragment FolderFields on Folder {
  id
  name
  uploadsCount
  parentFolderId
  InputWorkflowTriggers {
    id
  }
}
    `;
export const WorkflowFieldsFragmentDoc = gql`
    fragment WorkflowFields on Workflow {
  id
  name
  Triggers {
    id
    InputFolder {
      ...FolderFields
    }
    OutputFolder {
      ...FolderFields
    }
  }
  Parameters {
    id
    upscalingFactor
    upscalingCustomWidth
    artifactsDetectionRemoval
    enhanceFaceDetails
    preset
  }
}
    ${FolderFieldsFragmentDoc}`;
export const ApiKeyFieldsFragmentDoc = gql`
    fragment ApiKeyFields on ApiKey {
  id
  label
  key
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment UserFields on User {
  email
  id
  status
  mustValidateUrgently
  isOAuth
  activePlan
  Profile {
    id
    avatarUrl
    firstName
    lastName
    salutation
    dateOfBirth
    country
    companyName
    companyRole
  }
}
    `;
export const WorkflowTriggerFieldsFragmentDoc = gql`
    fragment WorkflowTriggerFields on WorkflowTrigger {
  id
  Workflow {
    id
    name
  }
}
    `;
export const ApiKeysDocument = gql`
    query ApiKeys {
  apiKeys {
    ...ApiKeyFields
  }
}
    ${ApiKeyFieldsFragmentDoc}`;
export const CreateApiKeyDocument = gql`
    mutation CreateApiKey($label: String!) {
  createApiKey(label: $label) {
    ...ApiKeyFields
  }
}
    ${ApiKeyFieldsFragmentDoc}`;
export const DeleteApiKeyDocument = gql`
    mutation DeleteApiKey($id: String!) {
  deleteApiKey(id: $id)
}
    `;
export const RefreshApiKeyDocument = gql`
    mutation RefreshApiKey($id: String!) {
  refreshApiKey(id: $id) {
    ...ApiKeyFields
  }
}
    ${ApiKeyFieldsFragmentDoc}`;
export const ApiKeysUsageDocument = gql`
    query ApiKeysUsage($apiKeyId: String, $date: String) {
  apiKeysUsage(apiKeyId: $apiKeyId, date: $date) {
    _id
    uploadsFinished
    enhancementsCreated
    creditsUsed
    averageEnhancementDuration
  }
}
    `;
export const MeDocument = gql`
    query Me {
  me {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($input: UpdateProfileInput!) {
  updateProfile(input: $input) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export const DeleteMeDocument = gql`
    mutation DeleteMe($input: DeleteMeInput!) {
  deleteMe(input: $input)
}
    `;
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($input: UpdatePasswordInput!) {
  updatePassword(input: $input)
}
    `;
export const UpdateEmailAddressDocument = gql`
    mutation UpdateEmailAddress($input: UpdateEmailAddressInput!) {
  updateEmailAddress(input: $input)
}
    `;
export const SignupDocument = gql`
    mutation Signup($input: SignupInput!) {
  signup(input: $input)
}
    `;
export const ConfirmSignupEmailAddressDocument = gql`
    mutation ConfirmSignupEmailAddress($input: ConfirmSignupEmailAddressInput!) {
  confirmSignupEmailAddress(input: $input)
}
    `;
export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
  login(input: $input) {
    id
    email
  }
}
    `;
export const RequestResetPasswordDocument = gql`
    mutation RequestResetPassword($input: RequestResetPasswordInput!) {
  requestResetPassword(input: $input)
}
    `;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input)
}
    `;
export const ResendEmailConfirmationDocument = gql`
    mutation ResendEmailConfirmation($input: ResendEmailConfirmationInput!) {
  resendEmailConfirmation(input: $input)
}
    `;
export const ContactSupportDocument = gql`
    mutation ContactSupport($input: ContactSupportInput!) {
  contactSupport(input: $input)
}
    `;
export const AccountDocument = gql`
    query Account {
  account {
    id
    AccountSubscription {
      id
      Plan {
        id
        name
      }
    }
    stripeSubscription {
      cancel_at_period_end
      current_period_end
    }
  }
}
    `;
export const CreditsDocument = gql`
    query Credits {
  credits {
    id
    periodStart
    periodEnd
    usedCreditsThisPeriod
    remainingCreditsThisPeriod
  }
}
    `;
export const CreateEnhancedImageDocument = gql`
    mutation CreateEnhancedImage($uploadId: String!, $input: CreateEnhancedImageInput!) {
  createEnhancedImage(uploadId: $uploadId, input: $input) {
    ...EnhancedImageFields
  }
}
    ${EnhancedImageFieldsFragmentDoc}`;
export const EnhancedImageDocument = gql`
    query EnhancedImage($id: String!) {
  enhancedImage(id: $id) {
    ...EnhancedImageFields
  }
}
    ${EnhancedImageFieldsFragmentDoc}`;
export const EnhancedImageWithFeedbackDocument = gql`
    query EnhancedImageWithFeedback($id: String!) {
  enhancedImage(id: $id) {
    ...EnhancedImageFields
    EnhancedImageFeedback {
      id
      score
      comment
    }
  }
}
    ${EnhancedImageFieldsFragmentDoc}`;
export const EnhancedImageCompletedDocument = gql`
    query EnhancedImageCompleted($id: String!) {
  enhancedImageCompleted(id: $id) {
    ...EnhancedImageFields
  }
}
    ${EnhancedImageFieldsFragmentDoc}`;
export const UpsertEnhancedImageFeedbackDocument = gql`
    mutation UpsertEnhancedImageFeedback($enhancedImageId: String!, $input: UpsertEnhancedImageFeedbackInput!) {
  upsertEnhancedImageFeedback(enhancedImageId: $enhancedImageId, input: $input)
}
    `;
export const CreateExportDocument = gql`
    mutation CreateExport($uploadIds: [String!]!) {
  createExport(uploadIds: $uploadIds) {
    downloadUrl
    filename
    enhancedImagesCount
    unEnhancedImagesCount
    exportCreatedAsync
  }
}
    `;
export const FoldersDocument = gql`
    query Folders($first: Int!, $after: String, $filter: FoldersFilterInput) {
  folders(first: $first, after: $after, filter: $filter) {
    totalCount
    pageInfo {
      hasNextPage
    }
    edges {
      cursor
      node {
        ...FolderFields
      }
    }
  }
}
    ${FolderFieldsFragmentDoc}`;
export const FolderDocument = gql`
    query Folder($id: String!) {
  folder(id: $id) {
    ...FolderFields
    InputWorkflowTriggers {
      ...WorkflowTriggerFields
    }
    OutputWorkflowTriggers {
      ...WorkflowTriggerFields
    }
  }
  folderBreadcrumb(id: $id) {
    id
    name
  }
}
    ${FolderFieldsFragmentDoc}
${WorkflowTriggerFieldsFragmentDoc}`;
export const CreateFolderDocument = gql`
    mutation CreateFolder($input: CreateFolderInput!) {
  createFolder(input: $input) {
    ...FolderFields
  }
}
    ${FolderFieldsFragmentDoc}`;
export const UpdateFolderDocument = gql`
    mutation UpdateFolder($id: String!, $input: UpdateFolderInput!) {
  updateFolder(id: $id, input: $input) {
    ...FolderFields
  }
}
    ${FolderFieldsFragmentDoc}`;
export const DeleteFolderDocument = gql`
    mutation DeleteFolder($id: String!) {
  deleteFolder(id: $id)
}
    `;
export const CreatePortalSessionDocument = gql`
    mutation CreatePortalSession($input: CreatePortalSessionInput!) {
  createPortalSession(input: $input) {
    url
  }
}
    `;
export const CreateCheckoutSessionDocument = gql`
    mutation CreateCheckoutSession($input: CreateCheckoutSessionInput!) {
  createCheckoutSession(input: $input) {
    url
  }
}
    `;
export const PaymentMethodsDocument = gql`
    query PaymentMethods {
  paymentMethods {
    items {
      card {
        last4
        exp_month
        exp_year
        brand
      }
      billing_details {
        name
        address {
          line1
          line2
          state
          postal_code
          country
          city
        }
      }
    }
  }
}
    `;
export const InvoicesDocument = gql`
    query Invoices {
  invoices {
    hasMore
    items {
      id
      invoice_pdf
      amount_due
      amount_remaining
      period_start
      period_end
      currency
    }
  }
}
    `;
export const CancelSubscriptionDocument = gql`
    mutation CancelSubscription($input: CancelSubscriptionInput!) {
  cancelSubscription(input: $input)
}
    `;
export const TasksListDocument = gql`
    query TasksList {
  tasksList {
    _id
    hash
    inProgress {
      ...EnhancedImageFields
    }
    completed {
      ...EnhancedImageFields
    }
  }
}
    ${EnhancedImageFieldsFragmentDoc}`;
export const CreateSignedUploadUrlDocument = gql`
    mutation CreateSignedUploadUrl($input: CreateSignedUploadUrlInput!) {
  createSignedUploadUrl(input: $input) {
    uploadUrl
    uploadId
  }
}
    `;
export const CompleteSignedUploadDocument = gql`
    mutation CompleteSignedUpload($id: String!) {
  completeSignedUpload(id: $id) {
    ...UploadFields
  }
}
    ${UploadFieldsFragmentDoc}`;
export const UploadsDocument = gql`
    query Uploads($ids: [String!], $folderId: String, $after: String, $first: Int) {
  uploads(
    after: $after
    first: $first
    filter: {ids: $ids, type: WEB_ONLY, folderId: $folderId}
  ) {
    totalCount
    pageInfo {
      hasNextPage
    }
    edges {
      cursor
      node {
        ...UploadFields
      }
    }
  }
}
    ${UploadFieldsFragmentDoc}`;
export const UploadDocument = gql`
    query Upload($id: String!) {
  upload(id: $id) {
    ...UploadFields
  }
}
    ${UploadFieldsFragmentDoc}`;
export const DeleteUploadsDocument = gql`
    mutation DeleteUploads($ids: [String!]!) {
  deleteUploads(ids: $ids) {
    deletedUploadIds
  }
}
    `;
export const WorkflowsDocument = gql`
    query Workflows {
  workflows {
    totalCount
    pageInfo {
      hasNextPage
    }
    edges {
      node {
        ...WorkflowFields
      }
    }
  }
}
    ${WorkflowFieldsFragmentDoc}`;
export const WorkflowDocument = gql`
    query Workflow($id: String!) {
  workflow(id: $id) {
    ...WorkflowFields
  }
}
    ${WorkflowFieldsFragmentDoc}`;
export const CreateWorkflowDocument = gql`
    mutation CreateWorkflow($name: String!) {
  upsertWorkflow(input: {name: $name}) {
    ...WorkflowFields
  }
}
    ${WorkflowFieldsFragmentDoc}`;
export const UpsertWorkflowDocument = gql`
    mutation UpsertWorkflow($input: WorkflowUpsertInput!) {
  upsertWorkflow(input: $input) {
    ...WorkflowFields
  }
}
    ${WorkflowFieldsFragmentDoc}`;
export const DeleteWorkflowDocument = gql`
    mutation DeleteWorkflow($workflowId: String!) {
  deleteWorkflow(workflowId: $workflowId)
}
    `;
export const UpdateWorkflowSettingsDocument = gql`
    mutation UpdateWorkflowSettings($id: String!, $input: UpsertWorkflowSettingsInput!) {
  updateWorkflowSettings(workflowId: $id, input: $input) {
    ...WorkflowFields
  }
}
    ${WorkflowFieldsFragmentDoc}`;
export const UpsertWorkflowTriggersDocument = gql`
    mutation UpsertWorkflowTriggers($workflowId: String!, $input: UpsertWorkflowTriggersInput!) {
  upsertWorkflowTriggers(workflowId: $workflowId, input: $input) {
    ...WorkflowFields
  }
}
    ${WorkflowFieldsFragmentDoc}`;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    ApiKeys(variables?: ApiKeysQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ApiKeysQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ApiKeysQuery>(ApiKeysDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ApiKeys', 'query');
    },
    CreateApiKey(variables: CreateApiKeyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateApiKeyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateApiKeyMutation>(CreateApiKeyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateApiKey', 'mutation');
    },
    DeleteApiKey(variables: DeleteApiKeyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteApiKeyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteApiKeyMutation>(DeleteApiKeyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteApiKey', 'mutation');
    },
    RefreshApiKey(variables: RefreshApiKeyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RefreshApiKeyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RefreshApiKeyMutation>(RefreshApiKeyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RefreshApiKey', 'mutation');
    },
    ApiKeysUsage(variables?: ApiKeysUsageQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ApiKeysUsageQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ApiKeysUsageQuery>(ApiKeysUsageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ApiKeysUsage', 'query');
    },
    Me(variables?: MeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MeQuery>(MeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Me', 'query');
    },
    UpdateProfile(variables: UpdateProfileMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateProfileMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateProfileMutation>(UpdateProfileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateProfile', 'mutation');
    },
    DeleteMe(variables: DeleteMeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteMeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteMeMutation>(DeleteMeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteMe', 'mutation');
    },
    UpdatePassword(variables: UpdatePasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdatePasswordMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdatePasswordMutation>(UpdatePasswordDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdatePassword', 'mutation');
    },
    UpdateEmailAddress(variables: UpdateEmailAddressMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateEmailAddressMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateEmailAddressMutation>(UpdateEmailAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateEmailAddress', 'mutation');
    },
    Signup(variables: SignupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SignupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SignupMutation>(SignupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Signup', 'mutation');
    },
    ConfirmSignupEmailAddress(variables: ConfirmSignupEmailAddressMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ConfirmSignupEmailAddressMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ConfirmSignupEmailAddressMutation>(ConfirmSignupEmailAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ConfirmSignupEmailAddress', 'mutation');
    },
    Login(variables: LoginMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LoginMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<LoginMutation>(LoginDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Login', 'mutation');
    },
    RequestResetPassword(variables: RequestResetPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RequestResetPasswordMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RequestResetPasswordMutation>(RequestResetPasswordDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RequestResetPassword', 'mutation');
    },
    ResetPassword(variables: ResetPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ResetPasswordMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ResetPasswordMutation>(ResetPasswordDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ResetPassword', 'mutation');
    },
    ResendEmailConfirmation(variables: ResendEmailConfirmationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ResendEmailConfirmationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ResendEmailConfirmationMutation>(ResendEmailConfirmationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ResendEmailConfirmation', 'mutation');
    },
    ContactSupport(variables: ContactSupportMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ContactSupportMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ContactSupportMutation>(ContactSupportDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ContactSupport', 'mutation');
    },
    Account(variables?: AccountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AccountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<AccountQuery>(AccountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Account', 'query');
    },
    Credits(variables?: CreditsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreditsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreditsQuery>(CreditsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Credits', 'query');
    },
    CreateEnhancedImage(variables: CreateEnhancedImageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateEnhancedImageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateEnhancedImageMutation>(CreateEnhancedImageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateEnhancedImage', 'mutation');
    },
    EnhancedImage(variables: EnhancedImageQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<EnhancedImageQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<EnhancedImageQuery>(EnhancedImageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'EnhancedImage', 'query');
    },
    EnhancedImageWithFeedback(variables: EnhancedImageWithFeedbackQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<EnhancedImageWithFeedbackQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<EnhancedImageWithFeedbackQuery>(EnhancedImageWithFeedbackDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'EnhancedImageWithFeedback', 'query');
    },
    EnhancedImageCompleted(variables: EnhancedImageCompletedQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<EnhancedImageCompletedQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<EnhancedImageCompletedQuery>(EnhancedImageCompletedDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'EnhancedImageCompleted', 'query');
    },
    UpsertEnhancedImageFeedback(variables: UpsertEnhancedImageFeedbackMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpsertEnhancedImageFeedbackMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpsertEnhancedImageFeedbackMutation>(UpsertEnhancedImageFeedbackDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpsertEnhancedImageFeedback', 'mutation');
    },
    CreateExport(variables: CreateExportMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateExportMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateExportMutation>(CreateExportDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateExport', 'mutation');
    },
    Folders(variables: FoldersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FoldersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FoldersQuery>(FoldersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Folders', 'query');
    },
    Folder(variables: FolderQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FolderQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FolderQuery>(FolderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Folder', 'query');
    },
    CreateFolder(variables: CreateFolderMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateFolderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateFolderMutation>(CreateFolderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateFolder', 'mutation');
    },
    UpdateFolder(variables: UpdateFolderMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateFolderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateFolderMutation>(UpdateFolderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateFolder', 'mutation');
    },
    DeleteFolder(variables: DeleteFolderMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteFolderMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteFolderMutation>(DeleteFolderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteFolder', 'mutation');
    },
    CreatePortalSession(variables: CreatePortalSessionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreatePortalSessionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreatePortalSessionMutation>(CreatePortalSessionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreatePortalSession', 'mutation');
    },
    CreateCheckoutSession(variables: CreateCheckoutSessionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateCheckoutSessionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateCheckoutSessionMutation>(CreateCheckoutSessionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateCheckoutSession', 'mutation');
    },
    PaymentMethods(variables?: PaymentMethodsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PaymentMethodsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PaymentMethodsQuery>(PaymentMethodsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PaymentMethods', 'query');
    },
    Invoices(variables?: InvoicesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<InvoicesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<InvoicesQuery>(InvoicesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Invoices', 'query');
    },
    CancelSubscription(variables: CancelSubscriptionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CancelSubscriptionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CancelSubscriptionMutation>(CancelSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CancelSubscription', 'mutation');
    },
    TasksList(variables?: TasksListQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TasksListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<TasksListQuery>(TasksListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TasksList', 'query');
    },
    CreateSignedUploadUrl(variables: CreateSignedUploadUrlMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateSignedUploadUrlMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateSignedUploadUrlMutation>(CreateSignedUploadUrlDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateSignedUploadUrl', 'mutation');
    },
    CompleteSignedUpload(variables: CompleteSignedUploadMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CompleteSignedUploadMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CompleteSignedUploadMutation>(CompleteSignedUploadDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CompleteSignedUpload', 'mutation');
    },
    Uploads(variables?: UploadsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UploadsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UploadsQuery>(UploadsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Uploads', 'query');
    },
    Upload(variables: UploadQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UploadQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UploadQuery>(UploadDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Upload', 'query');
    },
    DeleteUploads(variables: DeleteUploadsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteUploadsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteUploadsMutation>(DeleteUploadsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteUploads', 'mutation');
    },
    Workflows(variables?: WorkflowsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<WorkflowsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<WorkflowsQuery>(WorkflowsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Workflows', 'query');
    },
    Workflow(variables: WorkflowQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<WorkflowQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<WorkflowQuery>(WorkflowDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Workflow', 'query');
    },
    CreateWorkflow(variables: CreateWorkflowMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateWorkflowMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateWorkflowMutation>(CreateWorkflowDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateWorkflow', 'mutation');
    },
    UpsertWorkflow(variables: UpsertWorkflowMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpsertWorkflowMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpsertWorkflowMutation>(UpsertWorkflowDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpsertWorkflow', 'mutation');
    },
    DeleteWorkflow(variables: DeleteWorkflowMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteWorkflowMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteWorkflowMutation>(DeleteWorkflowDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteWorkflow', 'mutation');
    },
    UpdateWorkflowSettings(variables: UpdateWorkflowSettingsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateWorkflowSettingsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateWorkflowSettingsMutation>(UpdateWorkflowSettingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateWorkflowSettings', 'mutation');
    },
    UpsertWorkflowTriggers(variables: UpsertWorkflowTriggersMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpsertWorkflowTriggersMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpsertWorkflowTriggersMutation>(UpsertWorkflowTriggersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpsertWorkflowTriggers', 'mutation');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;