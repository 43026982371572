import { uuid } from "@lib/uuid"
import produce from "immer"
import create, { GetState, SetState } from "zustand"
import { persist } from "zustand/middleware"
import shallow from "zustand/shallow"
import { createToast } from "./actions/toasts"
import { uploadImage } from "./actions/upload"
import * as editor from "./editor"
import * as toasts from "./toasts"
import { Action, StoreState } from "./types"
import * as uploads from "./uploads"

export const useStore = create<StoreState>(
  persist<StoreState>(
    (set: SetState<StoreState>, get: GetState<StoreState>) => ({
      isReady: false,
      toasts: toasts.slice(set, get),
      editor: editor.slice(set, get),
      uploads: uploads.slice(set, get),
      dispatch: async (action: Action) => {
        switch (action.type) {
          case "upload-image": {
            const taskId = uuid()
            await uploadImage({
              get,
              set,
              file: action.payload.file,
              folderId: action.payload.folderId,
              taskId,
            })

            break
          }
          case "upload-image-and-add-to-editor": {
            const taskId = uuid()
            const upload = await uploadImage({
              get,
              set,
              file: action.payload.file,
              taskId,
            })
            if (upload) {
              set(
                produce(get(), (draft) => {
                  draft.editor.uploadIds = [
                    ...draft.editor.uploadIds,
                    upload.id,
                  ]
                  if (!draft.editor.activeId) {
                    draft.editor.activeId = upload.id
                  }
                })
              )
            }

            break
          }
          case "replace-images-in-editor": {
            set(
              produce(get(), (draft) => {
                draft.editor.uploadIds = action.payload.uploadIds
                draft.editor.activeId = action.payload.uploadIds[0]
              })
            )

            break
          }
          case "remove-images-from-editor": {
            set(
              produce(get(), (draft) => {
                draft.editor.uploadIds = []
                draft.editor.activeId = null
              })
            )

            break
          }
          case "add-toast": {
            createToast({ set, get, action })
            break
          }
          case "clear-toast": {
            set(
              produce(get(), (draft) => {
                draft.toasts.byId = draft.toasts.byId.filter(
                  (id) => id !== action.id
                )
                delete draft.toasts.items[action.id]
              })
            )
            break
          }
          case "set-max-height-toast":
            set(
              produce(get(), (draft) => {
                draft.toasts.maxHeight = action.maxHeight
              })
            )
            break
          case "set-active-image-id":
            set(
              produce(get(), (draft) => {
                draft.editor.activeId = action.payload.id
              })
            )
            break
          default:
            break
        }
      },
    }),
    {
      name: "viesus-cloud-storage",
      partialize: (state) => ({
        editor: state.editor,
      }),
      onRehydrateStorage: () => (state, error) => {
        if (state && !error) {
          state.isReady = true
        }
      },
    }
  )
)

export const useDispatch = () => {
  const store = useStore(({ dispatch }) => ({ dispatch }), shallow)
  return store.dispatch
}

export const useToasts = () => {
  const store = useStore(({ toasts }) => ({ toasts }), shallow)
  return store.toasts
}
