import {
  CompleteSignedUploadDocument,
  CompleteSignedUploadMutation,
  CompleteSignedUploadMutationVariables,
  CreateSignedUploadUrlDocument,
  CreateSignedUploadUrlMutation,
  CreateSignedUploadUrlMutationVariables,
} from "@generated"
import { getGraphqlClient } from "@lib/graphql-client"
import { gtagEvent } from "@lib/gtag-event"
import { imgix } from "@lib/imgix"
import { preloadImage } from "@lib/preload-image"
import { queryClient } from "@lib/query-client"
import { uuid } from "@lib/uuid"
import axios from "axios"
import produce from "immer"
import { GetState, SetState } from "zustand"
import { StoreState } from "../types"
import { getId } from "./toasts"

const { client } = getGraphqlClient()

type UploadImageInput = {
  get: GetState<StoreState>
  set: SetState<StoreState>
  file: File
  taskId: string
  folderId?: string
}
export async function uploadImage({
  get,
  set,
  file,
  taskId,
  folderId,
}: UploadImageInput): Promise<
  NonNullable<CompleteSignedUploadMutation["completeSignedUpload"]> | undefined
> {
  // Create a new task
  createNewImageTaskFromFile({ get, set, file, taskId })

  // Try and upload the file and return when completed
  try {
    const completedUpload = await upload({
      file,
      folderId,
      updateUploadProgress: (progress) => {
        set(
          produce(get(), (draft) => {
            const task = draft.uploads.items[taskId]
            task.uploadProgress = progress
          })
        )
      },
    })
    if (completedUpload) {
      // Update the cache on each upload
      queryClient.invalidateQueries("uploads")
      set(
        produce(get(), (draft) => {
          const upload = draft.uploads.items[taskId]
          upload.status = "finished"
          upload.upload = completedUpload
        })
      )
    }
    return completedUpload
  } catch (error) {
    set(
      produce(get(), (draft) => {
        draft.uploads.items[taskId].status = "error"
        draft.uploads.items[taskId].errorCode = error.message

        const toastId = getId()
        draft.toasts.byId.push(toastId)
        draft.toasts.items[toastId] = {
          id: toastId,
          variant: "error",
          title: `There was an error uploading ${file.name}`,
          buttonAction: "progress-modal-errors",
          buttonText: "Show info",
        }
      })
    )
    if (process.env.NODE_ENV === "development") {
      console.error(error)
    }
  }
}

function createNewImageTaskFromFile({
  get,
  set,
  file,
  taskId,
}: UploadImageInput) {
  set(
    produce(get(), (draft) => {
      draft.uploads.byId.push(taskId)
      draft.uploads.items[taskId] = {
        id: taskId,
        imageId: uuid(),
        status: "uploading",
        filename: file.name,
        file,
        upload: null,
        uploadProgress: 0,
      }
    })
  )
}

type UploadInput = {
  file: File
  folderId?: string
  updateUploadProgress: (progress: number) => void
}
async function upload({
  file,
  folderId,
  updateUploadProgress,
}: UploadInput): Promise<
  NonNullable<CompleteSignedUploadMutation["completeSignedUpload"]>
> {
  const response = await client.request<
    CreateSignedUploadUrlMutation,
    CreateSignedUploadUrlMutationVariables
  >(CreateSignedUploadUrlDocument, {
    input: {
      filename: file.name,
      mimetype: file.type,
      filesize: file.size,
      folderId: folderId || null,
    },
  })
  if (!response?.createSignedUploadUrl) {
    throw new Error("Could not create signed upload url")
  }
  await axios(response.createSignedUploadUrl.uploadUrl, {
    method: "PUT",
    data: await file.arrayBuffer(),
    headers: {
      "Content-Type": file.type,
    },
    onUploadProgress: (event) => {
      const percentCompleted = Math.round((event.loaded * 100) / event.total)
      updateUploadProgress(percentCompleted)
    },
  })

  const completedUpload = await client.request<
    CompleteSignedUploadMutation,
    CompleteSignedUploadMutationVariables
  >(CompleteSignedUploadDocument, {
    id: response.createSignedUploadUrl.uploadId,
  })

  if (completedUpload?.completeSignedUpload?.url) {
    await preloadImage(
      imgix(completedUpload?.completeSignedUpload?.url, { width: 1000 })
    )
    await preloadImage(
      imgix(completedUpload?.completeSignedUpload?.url, { width: 200 })
    )
  }
  if (completedUpload?.completeSignedUpload) {
    gtagEvent("upload_finished")
  }
  if (!completedUpload?.completeSignedUpload) {
    throw new Error("Could not complete signed upload")
  }

  return completedUpload?.completeSignedUpload
}
