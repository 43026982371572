import React, { FC } from "react"
import { Icon, IconProps } from "./Icon"

export const IconCheck: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M26.667 8L12 22.667 5.333 16"
      ></path>
      <defs>
        <linearGradient
          id="check-gradient-fill"
          x1="2.4186"
          y1="3.58929"
          x2="10.1618"
          y2="4.29372"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#10C6FF" />
          <stop offset="1" stopColor="#7636FF" />
        </linearGradient>
      </defs>
    </Icon>
  )
}

export default IconCheck
