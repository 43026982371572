import { GetState, SetState } from "zustand"
import { StoreState } from "./types"

export type State = {
  uploadIds: string[]
  activeId: string | null
}

export const slice = (
  set: SetState<StoreState>,
  get: GetState<StoreState>
): State => ({
  uploadIds: [],
  activeId: null,
})
