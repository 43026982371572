import { cx } from "@linaria/core"
import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  forwardRef,
  HTMLAttributes,
} from "react"
import { ButtonIcon, getIcon } from "../button/helpers/get-icon"
import * as styles from "./styles"

type DefaultProps = {
  size?: "regular" | "small"
}

type LinkVariant = DefaultProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "color"> & {
    as?: "link"
  }

type ButtonVariant = DefaultProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, "color"> & {
    as?: "button"
  }
type SpanVariant = DefaultProps &
  Omit<HTMLAttributes<HTMLSpanElement>, "color"> & {
    as?: "span"
  }

type LinkType = LinkVariant | ButtonVariant | SpanVariant

type LinkProps = LinkType & {
  leftIcon?: ButtonIcon
}

export const Link = forwardRef<any, LinkProps>((props, ref) => {
  const {
    as = "link",
    className,
    size = "regular",
    leftIcon,
    children,
    ...rest
  } = props
  const Component = as === "link" ? "a" : as === "span" ? "span" : "button"
  return (
    // @ts-ignore
    <Component
      className={cx(styles.link, className)}
      ref={ref}
      data-size={size}
      {...rest}
    >
      {leftIcon && <span className={styles.icon}>{getIcon(leftIcon, 20)}</span>}
      {children}
    </Component>
  )
})

Link.displayName = "Link"
