import { getSdk, Sdk } from "@generated"
import { GraphQLClient } from "graphql-request"

export function getGraphQLApiUrl(): string {
  if (process.env.VERCEL_ENV === "development") {
    return "http://localhost:3000/graphql"
  }
  if (process.env.VERCEL_ENV === "preview") {
    if (!process.env.VERCEL_URL) {
      throw new Error("process.env.VERCEL_URL is not defined")
    }
    return `https://${process.env.VERCEL_URL}/graphql`
  }

  if (process.env.VERCEL_ENV === "production") {
    return `https://viesus.cloud/graphql`
  }

  // if process.env.VERCEL_ENV is not defined, we're on the client side
  // then we can simply return the relative /graphql url
  return "/graphql"
}

const client: GraphQLClient = new GraphQLClient(getGraphQLApiUrl())
const sdk = getSdk(client)

export function getGraphqlClient(): {
  sdk: Sdk
  client: GraphQLClient
} {
  return { sdk, client }
}
