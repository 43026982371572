import { CompleteSignedUploadMutation } from "@generated"
import { GetState, SetState } from "zustand"
import { StoreState } from "./types"

export type NewUploadTask = {
  id: string
  imageId: string // references the key on the images object
  status: Omit<TaskStatus, "enhancement-pending" | "enhancing">
  file: File
  filename: string
  upload: CompleteSignedUploadMutation["completeSignedUpload"] | null
  errorCode?: string
  uploadProgress: number
}

export type Task = NewUploadTask
export type TaskStatus =
  | "upload-pending"
  | "uploading"
  | "upload-completed"
  | "error"

export type State = {
  byId: string[]
  items: { [key: string]: Task }
}

export const slice = (
  set: SetState<StoreState>,
  get: GetState<StoreState>
): State => ({
  byId: [],
  items: {},
})
