type Args = {
  width?: number
}

export function imgix(url: string, args?: Args) {
  let params: string[] = []
  if (args && args.width) {
    params.push(`w=${args.width}`)
  }

  return `${url}?${params.join("&")}`
}
