type GtagActionBody = {
  category?: string
  label?: string
}
export function gtagEvent(action: string, body?: GtagActionBody) {
  const category = body?.category || ""
  const label = body?.label || ""
  if (window["gtag"]) {
    window["gtag"]("event", action, { category, label })
  }
}
